import { concat } from "lodash";
import React from "react";
import { OnlineServer } from "../../../Enums/OnlineServer";
import { ProductHelper } from "../../../Helpers/ProductHelper";
import { BazosHelper } from "../../../Helpers/Servers/BazosHelper";
import IGlobalSettings from "../../../Interfaces/IGlobalSettings";
import IProduct from "../../../Interfaces/IProduct";
import IProductCollection from "../../../Interfaces/IProductCollection";
import Product from "../../../Models/Product";
import BaseComponent from "../../BaseComponent";
import CheckBox from "../../UI/Input/CheckBox";
import InputText from "../../UI/Input/InputText";
import Textarea from "../../UI/Input/Textarea";
import { OnlineServerHelper } from "../../Utils/OnlineServerHelper";
import { ContentRulesAlert } from "../ContentRulesAlert";

interface IState {

}

interface IProps {
    product: IProduct,
    collection: IProductCollection,
    globalSettings: IGlobalSettings,
    onChange(value: any, key: string): void,
    validChanged?(isValid: boolean): void,
}

export default class BazosCzEditor extends BaseComponent<IProps, IState>
{
    private inputBazosCzName = React.createRef<InputText>();
    private inputBazosCzDescription = React.createRef<Textarea>();

    constructor(props: IProps) {
        super(props);

        this.inputBazosCzName = React.createRef();
        this.inputBazosCzDescription = React.createRef();
    }

    async componentDidMount() {
        await this.checkValidateAsync();
    }

    validateDescription(description: string, errors: string[], validateInput: Function) {
        concat(errors, BazosHelper.getDescriptionErrors(this.props.globalSettings, description));
        validateInput(description, errors);
    }

    getInputs() {
        return [this.inputBazosCzName, this.inputBazosCzDescription];
    }

    async onBlurCheckValidateAsync() {
        const inputs = this.getInputs();
        const isValid = await this.validateInputsAsync(inputs, false);
        this.props.validChanged(isValid);
    }

    async checkValidateAsync() {
        const inputs = this.getInputs();
        const isValid = await this.validateInputsAsync(inputs);
        this.props.validChanged(isValid);
    }

    async validateAsync(): Promise<boolean> {
        const inputs = this.getInputs();
        const isValid = await this.validateInputsAsync(inputs);

        this.props.validChanged(isValid);
        return isValid;
    }

    async validateNameOnServerAsync(name: string): Promise<string[]> {
        const product = this.props.product;

        if ((name || '').length > 0) {
            let errors: string[] = [];
            return this.productApiService.validateName(product.id, name, OnlineServer.BazosCz)
                .then(success => {
                    if (success === false) {
                        errors.push(`Název "${product.bazosCzName}" již existuje. Zvolte jiný.`);
                    }
                    return errors;
                })
        } 
    }

    render() {
        const { product, onChange, collection } = this.props;

        return (
            <>
                <div className="pb-3 pt-1">
                    <CheckBox
                        checked={product.bazosCzUseDescription}
                        onChange={(value) => onChange(value, 'bazosCzUseDescription')}
                        label="Nastavit jiný obsah"
                    />
                </div>

                <div className={product.bazosCzUseDescription ? 'd-block' : 'd-none'}>
                    <InputText
                        ref={this.inputBazosCzName}
                        label="Nadpis"
                        onChange={(value) => onChange(value, 'bazosCzName')}
                        value={product.bazosCzName}
                        required
                        forbidSpecialChars={true}
                        maxLength={ProductHelper.getMaxNameCharsCount()}
                        readonly={Product.isPendingCheck(product, collection)}
                        onBlur={this.onBlurCheckValidateAsync.bind(this)}
                        validateAsync={this.validateNameOnServerAsync.bind(this)}
                    />

                    <Textarea
                        ref={this.inputBazosCzDescription}
                        label="Popis"
                        onChange={(value) => onChange(value, 'bazosCzDescription')}
                        required
                        value={product.bazosCzDescription}
                        rows={13}
                        maxLength={BazosHelper.getBazosDescriptionMaxChars(collection)}
                        validate={this.validateDescription.bind(this)}
                        onBlur={this.onBlurCheckValidateAsync.bind(this)}
                    />

                    <ContentRulesAlert
                        text={product.bazosCzDescription}
                        rules={OnlineServerHelper.getContentRules(OnlineServer.BazosCz)}
                    />
                </div>
            </>)
    }
}