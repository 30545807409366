export enum ListType {
    VehicleTruckBody = 1,
    VehicleMotoBody = 2,
    VehicleMotoBrand = 3,
    ProductEquipment = 4,
    VehicleMotoEquipment = 5,

    TireWidth = 6,
    TireHeight = 7,
    TireRimDiameter = 8,
    TireLoadIndex = 9,
    TireConstructionType = 10,
    TireSeason = 11,
    TireVehicleType = 12,
    TireSpeedRating = 13,
    TireProfile = 15,
    Pcd = 16,

    Subcategories = 14,
    VehicleDrive = 17,
} 