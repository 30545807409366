import React, { useRef } from "react";
import { IValidationFormRef } from "../../../Interfaces/IValidationFormRef";
import IProduct from "../../../Interfaces/IProduct";
import IUser from "../../../Interfaces/IUser";
import IInputField from "../../../Interfaces/IInputField";
import { SectionTitle } from "../../Others/SectionTitle";
import { concat } from "lodash";
import Product from "../../../Models/Product";
import { VehicleType } from "../../../Enums/VehicleType";
import SmartSelectBox from "../../UI/Input/SmartSelectBox";
import { ProductType } from "../../../Enums/ProductType";
import SelectBox from "../../UI/Input/SelectBox";
import InputText from "../../UI/Input/InputText";
import IOptionData from "../../../Interfaces/IOptionData";
import InputUrl from "../../UI/Input/InputUrl";

interface IProps {
    product: IProduct,
    user: IUser,
    onChange(key: string, value: any): void,
    requiredFields: string[],
    subcategories: IOptionData[],
}

export const CategoriesFormCard = React.forwardRef<IValidationFormRef, IProps>((props, ref) => {

    const {
        product,
        onChange,
        user,
        subcategories,
        requiredFields
    } = props;

    React.useImperativeHandle(ref, () => ({
        getInputs,
    }));

    const getInputs = (): React.MutableRefObject<IInputField>[] => {
        var inputs: React.MutableRefObject<IInputField>[] = [sectionRef,
            vehicleTypeRef,
            brandRef,
            conditionRef,
            externalUrlRef];

        return concat(
            inputs,
           
        );
    }
    const sectionRef = useRef<SelectBox>(null);
    const vehicleTypeRef = useRef<SelectBox>(null);

    const brandRef = useRef<InputText>(null);
    const conditionRef = useRef<SelectBox>(null);
    const externalUrlRef = useRef<InputUrl>(null);

    const showConditionAndBrand = product.type === ProductType.Others || (product.vehicleType === VehicleType.Other ||
        product.vehicleType === VehicleType.SpareParts || product.vehicleType === VehicleType.WorkingMachines);

    return <>
        <div className="card mb-3">
            <div className="card-header">
                <SectionTitle title="Upřesnění" className="my-0" />
            </div>
            <div className="card-body">
                <div className="row">
                    {product.type === ProductType.Others &&
                        <div className="col-sm-6 col-lg-2">
                            <SelectBox
                                ref={sectionRef}
                                label="Sekce"
                                options={Product.getOthersSections()}
                                defaultValue={"Vyberte"}
                                value={product.section}
                                onChange={(val) => onChange('section', val)}
                                disabled={false}
                                required={requiredFields.includes("section")}
                            />
                        </div>}

                    {product.type === ProductType.Car &&
                        <div className="col-sm-6 col-lg-2">
                            <SelectBox
                                ref={vehicleTypeRef}
                                defaultValue="- nevybráno -"
                                label="Kategorie"
                                onChange={(val) => onChange('vehicleType', val)}
                                value={product.vehicleType}
                                options={Product.getVehicleTypeOptions()}
                                required
                            />
                        </div>}

                    {product.vehicleType === VehicleType.SpareParts &&
                        <div className="col-sm-6 col-lg-3">
                            <SmartSelectBox
                                label="Podkategorie"
                                onChange={(value, key) => onChange(key, parseInt(value))}
                                value={(product.subcategory || '').toString()}
                                options={subcategories}
                                uid="subcategory"
                                placeholder="Nevybráno / jiná"
                                required={requiredFields.includes("subcategory")}
                            />
                        </div>
                    }

                    {showConditionAndBrand && <>
                        <div className="col-sm-6 col-md-2">
                            <SelectBox
                                ref={conditionRef}
                                label="Stav"
                                onChange={(val) => onChange('condition', val)}
                                options={Product.getConditionOptions()}
                                required={requiredFields.includes("condition")}
                                defaultValue="Vyberte"
                                value={product.condition}
                            />
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-2">
                            <InputText
                                ref={brandRef}
                                label="Značka produktu"
                                value={product.brand}
                                required={requiredFields.includes("brand")}
                                onChange={(val) => onChange('brand', val)}
                                forbidSpecialChars={true}
                            />
                        </div>
                    </>}

                    {product.websiteId ? <>
                        <div className="col-md-6">
                            <InputText
                                label="Slug"
                                value={product.slug || ''}
                                onChange={(val) => onChange('slug', val)}
                            />
                            <small className="p-0 m-0">
                               Slug je část textu v URL adrese produktu na webu. Ponechte prázdné pro automatické doplnění.
                            </small>
                        </div>
                    </>
                        :
                        <div className="col-md-6">
                            <InputUrl
                                ref={externalUrlRef}
                                label="Externí url (na vašem webu)"
                                value={product.externalProductUrl || ''}
                                onChange={(val) => onChange('externalProductUrl', val)}
                                required={requiredFields.includes("externalProductUrl")}
                            />
                        </div>}         
                </div>
            </div>
        </div>
    </>
})