import React, { useState } from "react";
import IInputField from "../../../Interfaces/IInputField";
import SmartSelectBox from "./SmartSelectBox";
import MultiSelectBox from "./MultiSelectBox";
import IOptionData from "../../../Interfaces/IOptionData";
import { SectionTitle } from "../../Others/SectionTitle";
import { find } from "lodash";
import { inject, observer } from "mobx-react";
import ListsStore from "../../Stores/ListsStore";
import { VehicleType } from "../../../Enums/VehicleType";

interface IProps {
    values: {
        [key: string]: number[];
    };
    onChange(data: Map<number, number[]>): void,
    listsStore?: ListsStore,
}

export const InputCarBrandModels = inject('listsStore')(observer(React.forwardRef<IInputField, IProps>((props, ref) => {
    const { onChange, listsStore } = props;

    const supportedTypes =
        [VehicleType.Car, VehicleType.Commercial, VehicleType.Motorcycle, VehicleType.Truck];

    const convertToMap = (data: { [key: string]: number[] }): Map<number, number[]> => {
        const map = new Map<number, number[]>();

        if (data) {
            for (const [key, value] of Object.entries(data)) {
                map.set(parseInt(key) as number, value as number[]);
            }
        }      
        return map;
    }


    let values = convertToMap(props.values);

    if (!(values instanceof Map)) {
        values = new Map<number, number[]>;
    }

    const [form, setForm] = useState<Map<number, number[]>>(new Map(values));
    const [newModels, setNewModels] = useState<number[]>([]);
    const [selectedBrand, setSelectedBrand] = useState<number | null>(null);
    let availableModelsForNew: IOptionData[] = [];

    if (selectedBrand) {
        availableModelsForNew = listsStore.getBrandModelsOptions(selectedBrand, supportedTypes);
    }

    const brandsOptions = listsStore.getBrandsOptions([]);

    let selModels: IOptionData[] = [];
    newModels.map(model => {
        var x = find(availableModelsForNew, { value: model });

        selModels.push({
            label: x.label,
            value: model,
        });
    })

    const handleBrandChange = (oldBrand: number, newBrand: number) => {

        if (!newBrand) {
            form.delete(oldBrand);
            setForm(new Map(form));
            onChange(new Map(form));
        }

        const models = form.get(oldBrand);
        if (models) {
            form.delete(oldBrand);
            form.set(newBrand, models);
            setForm(new Map(form));
            onChange(new Map(form));
        }
    };

    const handleModelChange = (brand: number, selectedModels: number[]) => {

        form.set(brand, selectedModels);
        setForm(new Map(form));
        onChange(new Map(form));
    };

    const handleAdd = () => {
        if (selectedBrand) {
            const updatedData = new Map(form);
            updatedData.set(selectedBrand, newModels);
            setForm(updatedData);
            onChange(updatedData);
            // Clear the newModels state after adding
            setNewModels([]);
            // Optionally reset selectedBrand state if needed
            setSelectedBrand(null);
        }
    };

   
    return <>
        <div className="row">
            {Array.from(form.entries()).length > 0 && < div className="col-12 col-md-6">
                <SectionTitle title="Kompatibilní vozy" />
                <hr />

                {Array.from(form.entries()).map(([brand, models]) => {
                    const availableModels = listsStore.getBrandModelsOptions(brand, supportedTypes);

                    let selModels: IOptionData[] = [];
                    models.map(model => {
                        var x = find(availableModels, { value: model });

                        selModels.push({
                            label: x.label,
                            value: model,
                        });
                    })

                    const brandOpt = find(listsStore.getBrandsOptions(supportedTypes), { value: brand });

                    if (!brandOpt) {
                        return <></>
                    }

                    return <div key={brand} className="row">
                        <div className="col-12 col-sm-12 col-lg-4">
                            <SmartSelectBox
                                required
                                label="Značka"
                                onChange={(value) => handleBrandChange(brand, parseInt(value))}
                                value={brand}
                                options={[brandOpt]}
                                uid="vehicleBrand"
                            />
                        </div>
                        <div className="col-12 col-sm-12 col-lg-8">
                            <MultiSelectBox
                                required
                                label="Modely"
                                onChange={(selectedOptions) =>
                                    handleModelChange(brand, selectedOptions.map(s => parseInt(s)))
                                }
                                selected={selModels}
                                options={availableModels}
                                uid="vehicleBrand"
                            />
                        </div>
                    </div>
                })}
            </div>}

            <div className="col-12 col-md-6">
                <SectionTitle title="Přidat nový komp. vůz" />
                <hr />

                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                        <SmartSelectBox
                            label="Značka"
                            onChange={(value) => {
                                const n = parseInt(value);

                                if (Number.isNaN(n)) {
                                    setSelectedBrand(null);
                                }
                                else {
                                    setSelectedBrand(parseInt((value || null)) as number);
                                }
                                setNewModels([]);

                            }}
                            value={selectedBrand || ""}
                            options={brandsOptions}
                            uid="selectBrandForModel"
                        />
                    </div>

                    {selectedBrand &&
                        <div className="col-12 col-sm-6 col-md-6 col-lg-8">
                            <MultiSelectBox
                                label="Modely"
                                selected={selModels}
                                options={availableModelsForNew}
                                uid="newVehicleModels"
                                onChange={(selectedOptions) => setNewModels(selectedOptions.map(s => parseInt(s)))}
                            />
                        </div>
                    }
                </div>

                <button
                    className="btn btn-secondary"
                    onClick={handleAdd}
                >
                    Přidat
                </button>
            </div>
        </div>
    </>
})))