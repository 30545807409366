import { each } from 'lodash';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import IErrorFlash from '../../Interfaces/IErrorFlash';
import { accountTransactionStore, appIpAddressStore, autoImporterStore, bazosCouponStore, currentFrontEndVersion, currentUserStore, facebookCatalogStore, globalSettingsStore, listsStore, productCollectionStore, productImageStore, productLogStore, productServerAccountStore, productStore, proformaInvoiceStore, serverAccountStore, webmailStore, websiteStore } from '../../App';
import { toast } from 'react-toastify';
import IAlert from '../../Interfaces/IAlert';
import { ListType } from '../../Enums/ListType';
import IResponseData from '../../Interfaces/IResponseData';

export default class StoreUtil {

    public static process(data: IResponseData) {
        let records = data.records;

        let alerts: any = data.alerts;
        let flashes: any = data.flashes;
        let flashErrors: IErrorFlash[] = flashes.errors;
        let infoAlerts: IAlert[] = alerts.info;
        let successAlerts: IAlert[] = alerts.success;
        let errorAlerts: IAlert[] = alerts.error;
        let warningAlerts: IAlert[] = alerts.warning;
        const frontEndVersion = data.frontendVersion;

        if (frontEndVersion > currentFrontEndVersion) {
            Swal.fire({
                title: `Nová verze aplikace je dostupná`,
                html: `Aby se změny projevily, je nutné znovu načíst aplikaci.`,
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: `Aktualizovat`,
                cancelButtonText: 'Teď ne'
            }).then((result) => {
                if (result.value) {
                    window.location.reload();
                }
            })
        }

        var swals: SweetAlertOptions[] = [];
        for (var i = 0; i < flashErrors.length; i++) {
            swals.push({ title: flashErrors[i].title, text: flashErrors[i].message, icon: 'error' })
        }

        for (var i = 0; i < infoAlerts.length; i++) {
            swals.push({ title: infoAlerts[i].title, text: infoAlerts[i].message, icon: 'info' })
        }

        for (var i = 0; i < successAlerts.length; i++) {

            swals.push({ title: successAlerts[i].title, text: successAlerts[i].message, icon: 'success' })
        }

        for (var i = 0; i < errorAlerts.length; i++) {

            swals.push({ title: errorAlerts[i].title, text: errorAlerts[i].message, icon: 'error' })
        }

        for (var i = 0; i < warningAlerts.length; i++) {

            swals.push({ title: warningAlerts[i].title, text: warningAlerts[i].message, icon: 'warning' })
        }

        swals.forEach(s => {
            Swal.fire(s);
        })

        for (var i = 0; i < flashes.flashes.length; i++) {
            toast.info(flashes.flashes[i]);
        }

        if (data.lists) {
            each(data.lists.vehicleTruckBodies, (value: any, key: any) => {
                listsStore.set(ListType.VehicleTruckBody, key, value);
            });

            each(data.lists.vehicleMotoBodies, (value: any, key: any) => {
                listsStore.set(ListType.VehicleMotoBody, key, value);
            });

            each(data.lists.productEquipments, (value: any, key: any) => {
               listsStore.set(ListType.ProductEquipment, key, value);
            });

            each(data.lists.vehicleMotoEquipments, (value: any, key: any) => {
               listsStore.set(ListType.VehicleMotoEquipment, key, value);
            });

            each(data.lists.tireWidths, (value: any, key: any) => {
               listsStore.set(ListType.TireWidth, key, value);
            });

            each(data.lists.tireHeights, (value: any, key: any) => {
               listsStore.set(ListType.TireHeight, key, value);
            });

            each(data.lists.tireRimDiameters, (value: any, key: any) => {
               listsStore.set(ListType.TireRimDiameter, key, value);
            });

            each(data.lists.tireLoadIndexes, (value: any, key: any) => {
               listsStore.set(ListType.TireLoadIndex, key, value);
            });

            each(data.lists.tireConstructionTypes, (value: any, key: any) => {
               listsStore.set(ListType.TireConstructionType, key, value);
            });

            each(data.lists.tireSeasons, (value: any, key: any) => {
               listsStore.set(ListType.TireSeason, key, value);
            });

            each(data.lists.tireVehicleTypes, (value: any, key: any) => {
               listsStore.set(ListType.TireVehicleType, key, value);
            });

            each(data.lists.tireSpeedRatings, (value: any, key: any) => {
               listsStore.set(ListType.TireSpeedRating, key, value);
            });

            each(data.lists.tireProfiles, (value: any, key: any) => {
               listsStore.set(ListType.TireProfile, key, value);
            });

            each(data.lists.subcategories, (value: any, key: any) => {
               listsStore.set(ListType.Subcategories, key, value);
            });

            each(data.lists.pcd, (value: any, key: any) => {
               listsStore.set(ListType.Pcd, key, value);
            });

            each(data.lists.vehicleDrives, (value: any, key: any) => {
                listsStore.set(ListType.VehicleDrive, key, value);
            });

            if (data.lists.brandModels && data.lists.brandModels.length > 0) {
                listsStore.setBrandModels(data.lists.brandModels);
            }
        }

        each(records, (records: any, type: string) => {
            if (type === 'facebookCatalogs') {
               facebookCatalogStore.addRecords(records.items);
            }
            else if (type === 'currentUser') {
               currentUserStore.setCurrentUser(records);
            }
            else if (type === 'products') {
               productStore.addRecords(records.items);
               productStore.removeRecords(records.removeIds);
               productStore.addCounts(records.counts);
            }
            else if (type === 'productCollections') {
               productCollectionStore.addRecords(records.items);
               productCollectionStore.removeRecords(records.removeIds);
            }
            else if (type === 'productImages') {
               productImageStore.addRecords(records.items);
               productImageStore.removeRecords(records.removeIds);
            }
            else if (type === 'appIpAddresses') {
               appIpAddressStore.addRecords(records.items);
            }
            else if (type === 'productLogs') {
               productLogStore.addRecords(records.items);
            }
            else if (type === 'globalSettings') {
               globalSettingsStore.set(records);
            }
            else if (type === 'autoImporters') {
                autoImporterStore.addRecords(records.items);
                autoImporterStore.removeRecords(records.removeIds);
            }
            else if (type === 'websites') {
               websiteStore.addRecords(records.items);
            }
            else if (type === 'webmails') {
               webmailStore.addRecords(records.items);
            }
            else if (type === 'accountTransactions') {
               accountTransactionStore.addRecords(records.items);
            }
            else if (type === 'proformaInvoices') {
               proformaInvoiceStore.addRecords(records.items);
            }
            else if (type === 'bazosCoupons') {
               bazosCouponStore.addRecords(records.items);
            }
            else if (type === 'serverAccounts') {
               serverAccountStore.addRecords(records.items);
            }
            else if (type === 'productServerAccounts') {
               productServerAccountStore.addRecords(records.items);
            }
        });
    }
}