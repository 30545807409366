import IOptionData from '../Interfaces/IOptionData';
import { find, orderBy } from 'lodash';
import IProduct from '../Interfaces/IProduct';
import { VehicleFuelType } from '../Enums/VehicleFuelType';
import { VehicleCondition } from '../Enums/VehicleCondition';
import { VehicleColor } from '../Enums/VehicleColor';
import { VehicleEmissionClass } from '../Enums/VehicleEmissionClass';
import { OnlineServer } from '../Enums/OnlineServer';
import IProductServerInfo from '../Interfaces/IProductServerInfo';
import IProductCollection from '../Interfaces/IProductCollection';
import { VehicleType } from '../Enums/VehicleType';
import { VehicleStroke } from '../Enums/VehicleStroke';
import { IProductServerAccount } from '../Interfaces/IProductServerAccount';
import IUser from '../Interfaces/IUser';
import { autoImporterStore } from './../App';
import { ProductCondition } from '../Enums/ProductCondition';
import { Currency } from '../Enums/Currency';

export default class Product {

    public static getEditLink(product: IProduct) {
        return `/inzeraty/${product.id}/upravit`;
    }

    public static getVehicleYearOptions(): IOptionData[] {
        let options: IOptionData[] = [];

        for (var i = new Date().getFullYear(); i >= 1960; i--) {
            options.push({ label: i.toString(), value: i });
        }

        return options;
    }
    
    public static getVehicleBodyOptions(vehicleType: VehicleType): IOptionData[] {

        if (vehicleType === VehicleType.Car) {
            return [
                { label: "Hatchback", value: 2 },
                { label: "Kombi", value: 3 },
                { label: "Terénní", value: 21 },
                { label: "Sedan/Limuzína", value: 1 },
                { label: "Kupé", value: 4 },
                { label: "Kabriolet", value: 5 },
                { label: "Pick-up", value: 6 },
                { label: "Roadster", value: 7 },
                { label: "Liftback", value: 8 },
                { label: "SUV", value: 9 },
                { label: "MPV", value: 10 },
                { label: "VAN", value: 11 },
            ];
        }
        else if (vehicleType === VehicleType.Truck) {
            return [
                { label: "Tahač", value: 12 },
                { label: "Valník", value: 13 },
                { label: "Sklápěč", value: 14 },
                { label: "Cisterna", value: 15 },
                { label: "Chladící/Mrazící", value: 16 },
                { label: "Nosič kontejnerů", value: 17 },
                { label: "Přeprava aut", value: 18 },
                { label: "Speciální nástavba", value: 19 },
                { label: "Skříň", value: 20 },
                { label: "Autojeřáb", value: 23 },
                { label: "Přepravník dřeva", value: 24 },
                { label: "Přepravník zvířat", value: 25 },
            ];
        }
        else {
            return [];
        }
    }

    public static getEmissionClassOptions(): IOptionData[] {
        const options: IOptionData[] = [
            { label: this.getEmissionClassLabel(VehicleEmissionClass.Euro1), value: VehicleEmissionClass.Euro1 },
            { label: this.getEmissionClassLabel(VehicleEmissionClass.Euro2), value: VehicleEmissionClass.Euro2 },
            { label: this.getEmissionClassLabel(VehicleEmissionClass.Euro3), value: VehicleEmissionClass.Euro3 },
            { label: this.getEmissionClassLabel(VehicleEmissionClass.Euro4), value: VehicleEmissionClass.Euro4 },
            { label: this.getEmissionClassLabel(VehicleEmissionClass.Euro5), value: VehicleEmissionClass.Euro5 },
            { label: this.getEmissionClassLabel(VehicleEmissionClass.Euro6), value: VehicleEmissionClass.Euro6 },
        ]
        return options;
    }

    public static getEmissionClassLabel(emissionClass: VehicleEmissionClass): string {
        switch (emissionClass) {

            case VehicleEmissionClass.Euro1:
                return "EURO 1";

            case VehicleEmissionClass.Euro2:
                return "EURO 2";

            case VehicleEmissionClass.Euro3:
                return "EURO 3";

            case VehicleEmissionClass.Euro4:
                return "EURO 4";

            case VehicleEmissionClass.Euro5:
                return "EURO 5";

            case VehicleEmissionClass.Euro6:
                return "EURO 6";
        }
    }

    public static getFuelTypeOptions() {
        let options: IOptionData[] = [
            { label: this.getFuelTypeLabel(VehicleFuelType.Petrol), value: VehicleFuelType.Petrol },
            { label: this.getFuelTypeLabel(VehicleFuelType.Diesel), value: VehicleFuelType.Diesel },
            { label: this.getFuelTypeLabel(VehicleFuelType.LpgPetrol), value: VehicleFuelType.LpgPetrol },
            { label: this.getFuelTypeLabel(VehicleFuelType.Hybrid), value: VehicleFuelType.Hybrid },
            { label: this.getFuelTypeLabel(VehicleFuelType.CngPetrol), value: VehicleFuelType.CngPetrol },
            { label: this.getFuelTypeLabel(VehicleFuelType.Electric), value: VehicleFuelType.Electric },
            { label: this.getFuelTypeLabel(VehicleFuelType.Ethanol), value: VehicleFuelType.Ethanol },
            { label: this.getFuelTypeLabel(VehicleFuelType.Hydrogen), value: VehicleFuelType.Hydrogen },
            { label: this.getFuelTypeLabel(VehicleFuelType.Lng), value: VehicleFuelType.Lng },
        ];
        return options;
    }

    public static getFuelTypeLabel(fuelType: VehicleFuelType): string {
        switch (fuelType) {

            case VehicleFuelType.Petrol:
                return "Benzín";

            case VehicleFuelType.Diesel:
                return "Diesel";

            case VehicleFuelType.LpgPetrol:
                return "LPG + benzín";

            case VehicleFuelType.Electric:
                return "Elektro";

            case VehicleFuelType.Hybrid:
                return "Hybrid";

            case VehicleFuelType.CngPetrol:
                return "CNG + Benzín";

            case VehicleFuelType.Ethanol:
                return "Ethanol";

            case VehicleFuelType.Hydrogen:
                return "Vodík";

            case VehicleFuelType.Lng:
                return "LNG";
        }
    }

    public static getColorOptions() {
        let options: IOptionData[] = [
            { label: this.getColorLabel(VehicleColor.Beige), value: VehicleColor.Beige },
            { label: this.getColorLabel(VehicleColor.Black), value: VehicleColor.Black },
            { label: this.getColorLabel(VehicleColor.Blue), value: VehicleColor.Blue },
            { label: this.getColorLabel(VehicleColor.Brown), value: VehicleColor.Brown },
            { label: this.getColorLabel(VehicleColor.Gold), value: VehicleColor.Gold },
            { label: this.getColorLabel(VehicleColor.Gray), value: VehicleColor.Gray },
            { label: this.getColorLabel(VehicleColor.Green), value: VehicleColor.Green },
            { label: this.getColorLabel(VehicleColor.Orange), value: VehicleColor.Orange },
            { label: this.getColorLabel(VehicleColor.Pink), value: VehicleColor.Pink },
            { label: this.getColorLabel(VehicleColor.Purple), value: VehicleColor.Purple },
            { label: this.getColorLabel(VehicleColor.Red), value: VehicleColor.Red },
            { label: this.getColorLabel(VehicleColor.Silver), value: VehicleColor.Silver },
            { label: this.getColorLabel(VehicleColor.White), value: VehicleColor.White },
            { label: this.getColorLabel(VehicleColor.Yellow), value: VehicleColor.Yellow },
            { label: this.getColorLabel(VehicleColor.Other), value: VehicleColor.Other },
        ];
        return options;
    }

    public static getColorLabel(color: VehicleColor): string {
        switch (color) {

            case VehicleColor.Beige:
                return "Béžová";

            case VehicleColor.Black:
                return "Černá";

            case VehicleColor.Blue:
                return "Modrá";

            case VehicleColor.Brown:
                return "Hnědá";

            case VehicleColor.Gold:
                return "Zlatá";

            case VehicleColor.Gray:
                return "Šedá";

            case VehicleColor.Green:
                return "Zelená";

            case VehicleColor.Orange:
                return "Oranžová";

            case VehicleColor.Pink:
                return "Růžová";

            case VehicleColor.Purple:
                return "Fialová";

            case VehicleColor.Red:
                return "Červená";

            case VehicleColor.Silver:
                return "Stříbrná";

            case VehicleColor.White:
                return "Bílá";

            case VehicleColor.Yellow:
                return "Žlutá";

            case VehicleColor.Other:
                return "Jiná barva";
        }
    }

    public static getVehicleTransmissionOptions(): IOptionData[] {
        let options: IOptionData[] = [
            { label: "Manuální", value: 1 },
            { label: "Automatická", value: 2 },
            { label: "Poloautomatická", value: 3 },
        ];
        return options;
    }

    public static getVehicleTypeOptions(): IOptionData[] {
        let options: IOptionData[] = [
            { label: "Osobní auto", value: 1 },
            { label: "Užitkové auto", value: 2 },
            { label: "Čtyřkolka", value: 3 },
            { label: "Motorka", value: 4 },
            { label: "Náhradní díly", value: 6 },
            { label: "Obytné", value: 8 },
            { label: "Přívěsy a vozíky", value: 9 },
            { label: "Nákladní", value: 5 },
            { label: "Autobus", value: 10 },
            { label: "Pracovní stroje", value: 11 },
            { label: "Ostatní", value: 7 },
        ];
        // options = orderBy(options, opt => [opt.value], ['asc']);
        return options;
    }

    public static getVehicleConditionOptions(): IOptionData[] {
        let options: IOptionData[] = [
            { label: "Nové", value: VehicleCondition.New },
            { label: "Ojeté", value: VehicleCondition.Used },
            { label: "Předváděcí", value: VehicleCondition.Demo },
            { label: "Havarované", value: VehicleCondition.Demaged },
        ];
        options = orderBy(options, opt => [opt.value], ['asc']);
        return options;
    }

    public static getOthersSections(): IOptionData[] {
        let options: IOptionData[] = [
            //     { label: "Auto", value: 1 },
            { label: "Děti", value: 2 },
            { label: "Stavba, dílna, zahrada", value: 3 },
            { label: "Zvířata, rostliny", value: 4 },
            { label: "Elektro", value: 5 },
            { label: "Služby a řemesla", value: 6 },
            { label: "Elektronika", value: 7 },
            { label: "Sport", value: 8 },
            { label: "PC a výpočetní technika", value: 9 },
            { label: "Nábytek, bytové doplňky", value: 10 },
            { label: "Práce", value: 11 },
            //  { label: "Reality", value: 12 },
            { label: "Stroje", value: 13 },
            { label: "Foto", value: 14 },
            { label: "Hudba", value: 15 },
            { label: "Vstupenky", value: 16 },
            { label: "Knihy", value: 17 },
            { label: "Oblečení", value: 18 },
            { label: "Reality", value: 20 },
            { label: "Ostatní", value: 19 },
        ];

        options = orderBy(options, opt => [opt.label], ['asc']);

        return options;
    }

    public static getProductServerInfo(
        product: IProduct,
        collection: IProductCollection,
        server: OnlineServer,
        user: IUser,
        productServerAccounts?: IProductServerAccount[]
    ): IProductServerInfo {

        switch (server) {
            case OnlineServer.Sauto:
                const importer = autoImporterStore.findById(product.autoImporterId);

                if (importer) {
                    return {
                        productId: product.id,
                        isVisible: true,
                        server: server,
                        enabled: true,
                        url: product.sautoOfferUrl,
                    };
                }
                break;

            case OnlineServer.BazosCz:
                return {
                    productId: product.id,
                    isVisible: product.bazosCzVisible,
                    url: product.bazosCzUrl,
                    visibleCheck: product.bazosCzVisibleCheck,
                    server: server,
                    enabled: product.bazosCZ,
                    pendingEdit: product.bazosCzPendingEdit,
                    pendingDelete: product.bazosCzPendingDelete,
                    pendingUpload: product.bazosCzPendingUpload,
                    pendingCheck: product.bazosCzPendingCheck,
                    nextRenewDateUtc: product.bazosCzNextRenewDateUtc,
                    error: product.bazosCzError,
                    serverRequiredAction: collection.bazosCzRequiredSignIn,
                    isTop: product.bazosCzIsTop,
                    enableUploadTopped: collection.bazosCzEnableUploadTopped,
                    errorType: product.bazosCzErrorActionType,
                    password: product.bazosCzPassword,
                }

            case OnlineServer.BazosSk:
                return {
                    productId: product.id,
                    isVisible: product.bazosSkVisible,
                    url: product.bazosSkUrl,
                    visibleCheck: product.bazosSkVisibleCheck,
                    server: server,
                    enabled: product.bazosSK,
                    pendingEdit: product.bazosSkPendingEdit,
                    pendingDelete: product.bazosSkPendingDelete,
                    pendingUpload: product.bazosSkPendingUpload,
                    pendingCheck: product.bazosSkPendingCheck,
                    nextRenewDateUtc: product.bazosSkNextRenewDateUtc,
                    error: product.bazosSkError,
                    serverRequiredAction: collection.bazosSkRequiredSignIn,
                    isTop: product.bazosSkIsTop,
                    enableUploadTopped: collection.bazosSkEnableUploadTopped,
                    errorType: product.bazosSkErrorActionType,
                    password: product.bazosSkPassword,
                }

            case OnlineServer.Sbazar:

                if (product.sbazarAccountId) {
                    const productServerAccount: IProductServerAccount =
                        find(productServerAccounts || [], { productId: product.id, serverAccountId: product.sbazarAccountId  });

                
                    if (productServerAccount) {
                        return {
                            productId: product.id,
                            isVisible: productServerAccount.offerIsVisible,
                            url: productServerAccount.offerUrl,
                            visibleCheck: productServerAccount.lastVisibleCheck,
                            server: server,
                            enabled: product.sbazar,
                            pendingEdit: productServerAccount.offerPendingEdit,
                            pendingDelete: productServerAccount.offerPendingDelete,
                            pendingUpload: productServerAccount.offerPendingUpload,
                            pendingCheck: productServerAccount.offerPendingCheck,
                            error: productServerAccount.offerError,
                            serverRequiredAction: productServerAccount.offerError,
                            isTop: false,
                            enableUploadTopped: false,
                            errorType: productServerAccount.offerErrorActionType,
                        };
                    }
                }

                return {
                    productId: product.id,
                    isVisible: product.sbazarVisible,
                    url: `https://www.sbazar.cz/${collection.sbazarShopName}/detail/${product.sbazarOfferId}`, // product.sbazarUrl,
                    visibleCheck: product.sbazarVisibleCheck,
                    server: server,
                    enabled: product.sbazar,
                    pendingEdit: product.sbazarPendingEdit,
                    pendingDelete: product.sbazarPendingDelete,
                    pendingUpload: product.sbazarPendingUpload,
                    pendingCheck: product.sbazarPendingCheck,
                    nextRenewDateUtc: product.sbazarNextRenewDateUtc,
                    error: product.sbazarError,
                    serverRequiredAction: collection.sbazarError,
                    isTop: false,
                    enableUploadTopped: false,
                    errorType: product.sbazarErrorActionType,
                }


            case OnlineServer.TipMoto: 
                return {
                    productId: product.id,
                    isVisible: product.tipMoto,
                    server: server,
                    enabled: product.tipMoto && user.uploaderServers.includes(server),
                }

            case OnlineServer.AutobazarEu:
                return {
                    productId: product.id,
                    isVisible: product.autobazarEu,
                    server: server,
                    enabled: product.autobazarEu && user.uploaderServers.includes(server),
                }

            case OnlineServer.GoogleMerchant:
                return {
                    productId: product.id,
                    isVisible: product.googleMerchant,
                    server: server,
                    enabled: product.googleMerchant && user.uploaderServers.includes(server),
                }

            case OnlineServer.FacebookShop:
                return {
                    productId: product.id,
                    isVisible: product.facebookCatalogEnabled,
                    server: server,
                    enabled: product.facebookCatalogEnabled,
                }

            case OnlineServer.Website:
                return {
                    productId: product.id,
                    isVisible: product.websiteId != null ? true : false,
                    server: server,
                    enabled: product.websiteId != null ? true : false,
                    url: product.externalProductUrl,
                }

            case OnlineServer.FacebookMarketplace:
                const productServerAccount: IProductServerAccount = find(productServerAccounts || [], { productId: product.id as any, serverAccountId: product.facebookAccountId as any });

                if (productServerAccount) {
                    return {
                        productId: product.id,
                        isVisible: productServerAccount.offerIsVisible,
                        url: productServerAccount.offerUrl,
                        visibleCheck: productServerAccount.lastVisibleCheck,
                        server: server,
                        enabled: product.facebookMarketplaceEnabled,
                        pendingEdit: productServerAccount.offerPendingEdit,
                        pendingDelete: productServerAccount.offerPendingDelete,
                        pendingUpload: productServerAccount.offerPendingUpload,
                        pendingCheck: productServerAccount.offerPendingCheck,
                        error: productServerAccount.offerError,
                        serverRequiredAction: productServerAccount.offerError,
                        isTop: false,
                        enableUploadTopped: false,
                        errorType: productServerAccount.offerErrorActionType,
                    };
                }
        }

        return {
            productId: product.id,
            isVisible: false,
            server: server,
            enabled: false,
        };
    }

    public static isPendingCheck(product: IProduct, collection: IProductCollection) {
        if (product.bazosCZ && collection.bazosCZ && product.bazosCzPendingCheck) {
            return true;
        }

        if (product.bazosSK && collection.bazosSK && product.bazosSkPendingCheck) {
            return true;
        }

        if (product.sbazar && collection.sbazar && product.sbazarPendingCheck) {
            return true;
        }
        return false;
    }

    public static isVisibleSomewhere(product: IProduct, collection: IProductCollection, productServerAccounts: IProductServerAccount[]) {
        if (product.bazosCZ && collection.bazosCZ && product.bazosCzVisible) {
            return true;
        }
        if (product.bazosSK && collection.bazosSK && product.bazosSkVisible) {
            return true;
        }
        if (product.sbazar && collection.sbazar && product.sbazarVisible) {
            return true;
        }

        if (product.facebookMarketplaceEnabled) {
            var productFbServerAccount = find(productServerAccounts, { productId: product.id as any, serverAccountId: product.facebookAccountId as any });

            if (productFbServerAccount && productFbServerAccount.offerIsVisible) {
                return true;
            }
        }

        if (product.sbazar && product.sbazarAccountId) {
            var productSbazarServerAccount = find(productServerAccounts, { productId: product.id, serverAccountId: product.sbazarAccountId });

            if (productSbazarServerAccount && productSbazarServerAccount.offerIsVisible) {
                return true;
            }
        }

        return false;
    }

    public static getVehicleStrokes(): IOptionData[] {
        let options: IOptionData[] = [
            { label: "2T", value: VehicleStroke.TwoStroke },
            { label: "4T", value: VehicleStroke.FourStore },
        ];
        return options;
    }

  
    public static getVehicleMotYearOptions(): IOptionData[] {
        let options: IOptionData[] = [];

        for (var i = new Date().getFullYear(); i <= new Date().getFullYear() + 5; i++) {
            options.push({ label: i.toString(), value: i });
        }

        return options;
    }

    public static getVehicleMotMonthOptions(): IOptionData[] {
        let options: IOptionData[] = [];

        for (var i = 1; i <= 12; i++) {
            options.push({ label: i.toString(), value: i });
        }

        return options;
    }

    public static getConditionOptions(): IOptionData[]  {
        let options: IOptionData[] = [
            { label: this.getConditionLabel(ProductCondition.New), value: ProductCondition.New },
            { label: this.getConditionLabel(ProductCondition.Used), value: ProductCondition.Used },
        ];
        return options;
    }

    public static getConditionLabel(condition: ProductCondition): string {
        switch (condition) {

            case ProductCondition.New:
                return "Nové";

            case ProductCondition.Used:
                return "Použité";

            default:
        }
    }

    public static getPriceByCurrency(currency: Currency, product: IProduct): number {

        switch (currency) {
            case Currency.Czk:
                return product.priceCZK;

            case Currency.Eur:
                return product.priceEUR;
        }
    }

    public static onChangePriceByCurrency(currency: Currency,  value: number, onChange: (key: string, value: any) => void) {
        switch (currency) {
            case Currency.Czk:
                onChange('priceCZK', value);
                break;

            case Currency.Eur:
                onChange('priceEUR', value)
                break;

            case Currency.PLN:
                onChange('pricePLN', value)
                break;

            default:
        }
    }
}