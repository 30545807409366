import { useRef } from "react";
import SmartSelectBox from "../../UI/Input/SmartSelectBox";
import IProduct from "../../../Interfaces/IProduct";
import ListsStore from "../../Stores/ListsStore";
import { inject, observer } from "mobx-react";
import { ListType } from "../../../Enums/ListType";
import { SectionTitle } from "../../Others/SectionTitle";
import React from "react";
import { IValidationFormRef } from "../../../Interfaces/IValidationFormRef";
import IInputField from "../../../Interfaces/IInputField";
import { InputCarBrandModels } from "../../UI/Input/InputCarBrandModels";
import ErrorWrapper from "../../Others/ErrorWrapper";

interface IProps {
    requiredFields: string[],
    product: IProduct,
    onChange(key: string, value: any): void,
    listsStore?: ListsStore,
}

export const AluminiumWheelsFormCard = inject('listsStore')(observer(React.forwardRef<IValidationFormRef, IProps>((props, ref) => {

    const { requiredFields, product, onChange, listsStore } = props;

    const rimDiameter = useRef<SmartSelectBox>(null);
    const pcd = useRef<SmartSelectBox>(null);

    React.useImperativeHandle(ref, () => ({
        getInputs,
    }));

    const getInputs = (): React.MutableRefObject<IInputField>[] => {
        return [
            rimDiameter,
            pcd,
        ]
    }

    const changeCarBrands = (brands: Map<number, number[]>) => {
        const data = product.customData || { brandsWithModels: new Map<number, number[]> };
        data.brandsWithModels = brands;
        onChange('customData', data);
    }

    return (
        <div className="card mb-3">
            <div className="card-header">
                <SectionTitle title="Technické údaje" className="my-0" />
            </div>
            <div className="card-body">
                <div className="row lightForm">
                    <div className="col-12 col-lg-8">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-4">
                                <SmartSelectBox
                                    ref={rimDiameter}
                                    label="Průměr"
                                    onChange={(value, key) => onChange(key, parseInt(value))}
                                    value={(product.tireRimDiameter || '').toString()}
                                    options={listsStore?.getOptionsBy(ListType.TireRimDiameter) || []}
                                    uid="tireRimDiameter"
                                    required={requiredFields.includes("tireRimDiameter")}
                                />
                            </div>

                            <div className="col-12 col-sm-6 col-md-4">
                                <SmartSelectBox
                                    ref={pcd}
                                    label="Rozteč"
                                    onChange={(value, key) => onChange(key, parseInt(value))}
                                    value={(product.pcd || '').toString()}
                                    options={listsStore?.getOptionsBy(ListType.Pcd) || []}
                                    uid="pcd"
                                    required={requiredFields.includes("pcd")}
                                />
                            </div>   
                        </div>
                    </div>
                </div>

                <ErrorWrapper>
                    <InputCarBrandModels
                        values={product.customData?.brandsWithModels}
                        onChange={(brands) => changeCarBrands(brands)}
                    />
                </ErrorWrapper>
            </div>
        </div>
    );
})))