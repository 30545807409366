import IProduct from '../../Interfaces/IProduct';
import GlobalSettingsStore from '../Stores/GlobalSettingsStore';
import { inject, observer } from 'mobx-react';
import FacebookCatalogStore from '../Stores/FacebookCatalogStore';
import WebsiteStore from '../Stores/WebsiteStore';
import { ShowDate } from '../Others/ShowDate';
import { ProductPrice } from '../Products/ProductPrice';
import ProductImageStore from '../Stores/ProductImageStore';
import ProductCollectionStore from '../Stores/ProductCollectionStore';
import { OnlineServerHelper } from '../Utils/OnlineServerHelper';
import { OnlineServer } from '../../Enums/OnlineServer';
import { ProductFilterActions } from './ProductFilterActions';
import CheckBox from '../UI/Input/CheckBox';
import { ProductServerStateLabel } from './ProductServerStateLabel';
import { ProductHelper } from '../../Helpers/ProductHelper';
import Product from '../../Models/Product';
import ProductServerAccountStore from '../Stores/ProductServerAccountStore';
import CurrentUserStore from '../Stores/CurrentUserStore';
import { ProductServersModal } from './ProductServersModal';
import SelectBox from '../UI/Input/SelectBox';
import { useEffect, useState } from 'react';
import ProductApiService from '../../Services/ProductApiService';
import IPatchObject from '../../Interfaces/IPatchObject';
import { PathOperation } from '../../Enums/PathOperation';
import { toast } from 'react-toastify';
import IOptionData from '../../Interfaces/IOptionData';
import ClickableWordsCopy from '../Utils/ClickableWordsCopy';
import { ProductImageCell } from './ProductImageCell';

interface IProps {
    product: IProduct,
    goToCollectionButton?: boolean,
    onlineServer?: OnlineServer,
    checked: boolean,
    onCheckedChange(value: boolean): void,
    checkable?: boolean,
    showGoToCollectionButton?: boolean,
    globalSettingsStore?: GlobalSettingsStore,
    facebookCatalogStore?: FacebookCatalogStore,
    websiteStore?: WebsiteStore,
    productImageStore?: ProductImageStore,
    productCollectionStore?: ProductCollectionStore,
    productServerAccountStore?: ProductServerAccountStore,
    currentUserStore?: CurrentUserStore,
}

const productApiService = new ProductApiService();

export const ProductFilterTableItem = inject(
    'globalSettingsStore',
    'facebookCatalogStore',
    'websiteStore',
    'productImageStore',
    'productCollectionStore',
    'productServerAccountStore',
    'currentUserStore')(observer((props: IProps) => {

        useEffect(() => {
            return (() => {
                productApiService.cancelRequests();
            })
        }, [])

        const [showProductDetailsModal, setShowProductDetailsModal] = useState<boolean>(false);
        const [modalDefaultKey, setModalDefaultKey] = useState<string>('');

        const product = props.product;

        if (!product) {
            return null;
        }

        const collection = props.productCollectionStore.getById(product.productCollectionId);
        const onlineServer = props.onlineServer;
        const { checked, onCheckedChange, checkable, showGoToCollectionButton } = props;
        const productServerAccounts = props.productServerAccountStore.getByProductId(product.id);
        const isInSale = (product.enabled === false && Product.isVisibleSomewhere(product, collection, productServerAccounts) === false) === false;
        const user = props.currentUserStore.user;
        const servers = ProductHelper.getOnlineServers(product, collection, user);

        const updateQuantity = (val: number) => {
            var objects: IPatchObject[] = [];
            objects.push({ op: PathOperation.replace, path: '/quantity', value: val });

            productApiService.patchProduct(product, objects)
                .then(data => {
                    if (data.success) {
                        toast.success("Změny uloženy");
                    }
                    else {
                        toast.error("Změny nebyly uloženy");
                    }
                })
        }

        let quantityOptions: IOptionData[] = [];
        for (var i = 1; i < 21; i++) {
            quantityOptions.push({ label: `Skladem: ${i}`, value: i },)
        }

        const renderClientCustom = () => {
            if (user.id === "4c0c0a71-b5db-41e5-bd39-2befb81f20f2") {
                // pneu teplice
                return <>&nbsp;/&nbsp;<ClickableWordsCopy text={product.note} /></>
            }
            return <></>
        }

        return (
            <>
                <tr key={product.id}>
                    {checkable && <td>
                        <CheckBox
                            checked={checked}
                            label=""
                            onChange={(value) => onCheckedChange(value)}
                        />
                    </td>}

                    <td className="position-relative">
                        <ProductImageCell
                            product={product}
                            onClick={() => onCheckedChange(!checked)}
                        />

                        {/* TOMAS MORAVEC */}
                        {(user.id === "dfdffc28-ce7e-40e0-9a75-e24ca471f474" ||
                        /* dev adam */   user.id === "b61a4110-db1f-4a2c-ac87-8577ea28f733") &&
                            <SelectBox
                                options={quantityOptions}
                                value={product.quantity}
                                label=""
                                onChange={(val) => updateQuantity(val)}
                                className="form-select no-border"
                            />}
                    </td>

                    <td style={{ width: '25%' }}>
                        <span className="">{OnlineServerHelper.getNameByServer(product, onlineServer)}</span> <br />
                        <small className='d-none d-md-flex text-secondary'>
                            <>
                                Vytvořeno&nbsp;<ShowDate date={product.dateCreated} />{renderClientCustom()}
                            </></small>
                    </td>

                    <td style={{ width: '50%' }}>
                        {!isInSale && product.delete === false ? <b className="text-secondary text-center ml-lg-5">Staženo z prodeje</b> :
                            <ul className="product-online-servers">
                                {servers.map((server) => {
                                    return (
                                        <li key={server}>
                                            <ProductServerStateLabel
                                                onlineServer={server}
                                                product={product}
                                                collection={collection}
                                                user={user}
                                                onClick={(server) => {
                                                    setShowProductDetailsModal(true);
                                                    setModalDefaultKey(server.toString());
                                                }}
                                            />
                                        </li>
                                    )
                                })}
                            </ul>}
                    </td>

                    <td>
                        <ProductPrice
                            product={product}
                        />
                    </td>

                    <td className="text-center">
                        {showProductDetailsModal &&
                            <ProductServersModal
                                onHide={() => setShowProductDetailsModal(false)}
                                product={product}
                                defaultServer={modalDefaultKey}
                                collection={collection}
                            />}

                        {(isInSale && !product.enabled) &&
                            <p className="text-secondary">
                                <b>Stahování z prodeje...</b>
                            </p>}

                        <ProductFilterActions
                            product={product}
                            showGoToCollectionButton={showGoToCollectionButton}
                        />
                    </td>
                </tr>
            </>
        )
    }))