import { filter } from "lodash"
import { Tab, Tabs } from "react-bootstrap"
import MultiRef from "react-multi-ref"
import { OnlineServer } from "../../../../Enums/OnlineServer"
import { VehicleType } from "../../../../Enums/VehicleType"
import IAutoImporter from "../../../../Interfaces/IAutoImporter"
import { IAutoImporterContent } from "../../../../Interfaces/IAutoImporterContent"
import IInputField from "../../../../Interfaces/IInputField"
import BaseComponent from "../../../BaseComponent"
import InputText from "../../../UI/Input/InputText"
import MultiNumberInput from "../../../UI/Input/MultiNumberInput"
import Textarea from "../../../UI/Input/Textarea"
import { OnlineServerHelper } from "../../../Utils/OnlineServerHelper"
import { AutoImporterContentPreviewModal } from "../AutoImporterContentPreviewModal"
import { ContentRulesAlert } from "../../../Products/ContentRulesAlert"

interface IState {
    activeTabKey: string,
    previewModal: {
        show: boolean,
        name: string,
        description: string,
        modalTitle: string,
        server: OnlineServer,
        content: IAutoImporterContent
    }
}

interface IProps {
    type: VehicleType,
    autoImporter: IAutoImporter,
    handleChange(type: VehicleType, server: OnlineServer, value: any, key: any): void,
}

export default class AutoImporterVehicleTypeContentEditor extends BaseComponent<IProps, IState> implements IInputField {
    _records = new MultiRef();

    constructor(props: IProps) {
        super(props);

        this.state = {
            activeTabKey: OnlineServer.BazosCz.toString(),
            previewModal: {
                show: false,
                name: '',
                description: '',
                modalTitle: '',
                server: null,
                content: null,
            }
        }
    }

    handleChange(type: VehicleType, server: OnlineServer, value: any, key: string) {
        this.props.handleChange(type, server, value, key);
    }

    handleGetPreview(content: IAutoImporterContent) {
        const { autoImporter } = this.props;

        this.autoimportersApiService
            .getPreview(autoImporter.id,
                {
                    designName: content.name,
                    designDescription: content.description,
                    onlineserver: content.onlineServer,
                    vehicleType: content.vehicleType
                })
            .then(response => {
                const preview = response.others.get("preview");

                if (preview) {
                    this.setState({
                        previewModal: {
                            show: true,
                            name: preview.name,
                            description: preview.description,
                            modalTitle: `Obsah ${OnlineServerHelper.getName(content.onlineServer)}`,
                            server: content.onlineServer,
                            content: content
                        }
                    })
                }
            })
    }

    validate() {
        let isValid = true;

        this._records.map.forEach((item: any, key: any) => {
            item.validate();
            const isRowValid = item.isValid();

            if (isValid === true) {
                isValid = isRowValid;

                if (isValid === false) {
                    const server = key[0];

                    this.setState({
                        activeTabKey: server
                    })
                }
            }
        });
        return isValid;
    }

    isValid(): boolean {
        return this.validate();
    }

    focus() {

    }

    handleHidePreview() {
        this.setState({
            previewModal: {
                ...this.state.previewModal,
                show: false,
            }
        })
    }

    render() {
        const { autoImporter } = this.props;
        const { activeTabKey, previewModal } = this.state;
        const contents = filter(autoImporter.contents, { vehicleType: this.props.type });

        return (
            <div className="nav-tabs-sauto-editor">

                {previewModal.show &&
                    <AutoImporterContentPreviewModal
                        modalTitle={previewModal.modalTitle}
                        name={previewModal.name}
                        description={previewModal.description}
                        onHide={this.handleHidePreview.bind(this)}
                        showOther={this.handleGetPreview.bind(this, previewModal.content)}
                    />}

                <Tabs
                    activeKey={activeTabKey}
                    id="uncontrolled-tab-example"
                    className="nav nav-justified"
                    onSelect={(key: string, e: any) => this.setState({ activeTabKey: key })}
                >
                    {contents.map(content => {
                        const key = content.onlineServer.toString();
                        const server = content.onlineServer;
                        const showImagesSequence = server === OnlineServer.BazosCz || server === OnlineServer.BazosSk || server === OnlineServer.Sbazar;
                        return (
                            <Tab
                                key={key}
                                eventKey={key}
                                title={OnlineServerHelper.getName(server)}
                                tabClassName="nav-item"
                            >
                                <InputText
                                    ref={this._records.ref(`${key}-name`)}
                                    label="Nadpis"
                                    onChange={(value) => this.handleChange(content.vehicleType, server, value, 'name')}
                                    value={content.name}
                                    required
                                />

                                <Textarea
                                    ref={this._records.ref(`${key}-description`)}
                                    label="Popis"
                                    onChange={(value) => this.handleChange(content.vehicleType, server, value, 'description')}
                                    required
                                    value={content.description}
                                    rows={13}
                                />

                                <div className="float-right">
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        onClick={() => this.handleGetPreview(content)}
                                    >
                                        Zobrazit náhled
                                    </button>
                                </div>

                                <ContentRulesAlert
                                    text={content.description}
                                    rules={OnlineServerHelper.getContentRules(server)}
                                />

                                <div className="mt-5">
                                    {showImagesSequence &&

                                        <MultiNumberInput
                                            ref={this._records.ref(`${key}-sequence`)}
                                            onChange={(value) => this.handleChange(content.vehicleType, server, value, 'imageSequence')}
                                            value={content.imageSequence}
                                            label="Pořadí obrázků"
                                            maxValueCount={OnlineServerHelper.getMaxImagesCount(server)}
                                            placeholder="1,2,3,4,5,6..."
                                        />
                                    }
                                </div>
                            </Tab>
                        )
                    })}
                </Tabs>
            </div>)
    }
}